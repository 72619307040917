module.exports = [
  {
    Action: "Delete Selected Rows",
    Shortcut: "Ctrl + Del (⌘ + Del)",
  },
  {
    Action: "Save changes",
    Shortcut: "Ctrl + S (⌘ + S)",
  },
  {
    Action: "Re-execute query",
    Shortcut: "Ctrl + R (⌘ + R)",
  },
  {
    Action: "Pause Fetching",
    Shortcut: "Space",
  },
  {
    Action: "Open new tab",
    Shortcut: "Ctrl + T (⌘ + T)",
  },
  {
    Action: "New Table",
    Shortcut: "Ctrl + N (⌘ + N)",
  },
  {
    Action: "Refresh",
    Shortcut: "Ctrl + R (⌘ + R)",
  },
  {
    Action: "Execute Command",
    Shortcut: "Ctrl + Enter (⌘ + Enter)",
  },
  {
    Action: "Toggle Table List",
    Shortcut: "Ctrl + B (⌘ + B)",
  },
  {
    Action: "Open History",
    Shortcut: "Ctrl + H (⌘ + H)",
  },
  {
    Action: "Open Settings",
    Shortcut: "Ctrl + , (⌘ + ,)",
  },
  {
    Action: "Minimize",
    Shortcut: "Ctrl + M (⌘ + M)",
  },
  {
    Action: "Select All",
    Shortcut: "Ctrl + A (⌘ + A)",
  },
  {
    Action: "Cut",
    Shortcut: "Ctrl + X (⌘ + X)",
  },
  {
    Action: "Copy",
    Shortcut: "Ctrl + C (⌘ + C)",
  },
  {
    Action: "Paste",
    Shortcut: "Ctrl + V (⌘ + V)",
  },
  {
    Action: "Bookmark/Favorite Query",
    Shortcut: "Ctrl + D (⌘ + D)",
  },
]

import React from "react"
import { Container } from "@components"

const ContentHero = props => (
  <React.Fragment>
    <div style={{ backgroundColor: "rgb(24, 144, 255)", width: "100%" }}>
      <Container>
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <h1
            style={{
              color: "white",
              fontWeight: 600,
              fontSize: "2.5rem",
              marginTop: "62px",
              width: "80%",
              textAlign: "center",
              paddingBottom: "6px",
              lineHeight: "1.5",
            }}
          >
            Dynobase Keyboard Shortcuts
          </h1>
        </div>
      </Container>
    </div>
  </React.Fragment>
)

export default ContentHero

import React from 'react';
import { Container } from '@components'
import { Box } from 'rebass'
import Reactable from 'reactable'
import data from './Data'
const Table = Reactable.Table;

const Content = () => (
       <Container>
           <Box mt={[32, 62]}>
            <Table data={data} className="table" id="keyboard-shortcuts" data={data} />
          </Box>
       </Container>     
    );

export default Content;